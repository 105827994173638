import React, {useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import VideoCard from "components/VideoCard";
import DynamicSubComponent from "components/dynamicSubComponent";
import { Pagination } from "swiper";
import "swiper/css/pagination";


function Testimonials({ blok }) {
  const title = blok.title;
  const left_copy = blok.left_copy;
  const bottom_title = blok.bottom_title;
  const paginationWrapper = useRef();

  const isLastSubBlock = (index) => {
    return index == blok.bottom_blocks.length - 1;
  };

  return (
    <section>
      <div className="section-container two-columns mb-[65px] lg:mb-5vw">
        <h2
          className="font-helvetica fs-24px text-wodden-purple font-medium"
          dangerouslySetInnerHTML={{ __html: title }}
        >
          {title}
        </h2>
        <p
          className="font-helvetica fs-24px text-gray-1 font-medium"
          dangerouslySetInnerHTML={{ __html: left_copy }}
        ></p>
      </div>

      <div className="mb-[120px] lg:mb-6vw">
        <Swiper
          slidesPerView={1.8}
          spaceBetween={40}
          centeredSlides={true}
          loop={true}
          breakpoints={{
            200: {
              slidesPerView: 1,
            },
            700: {
              slidesPerView: 1.8,
            },
          }}
          modules={[Pagination]}
          pagination={{
            el: ".bullet-pagination",
            bulletClass: "people-slider-bullet",
            bulletActiveClass: "active",
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + "" + "</span>";
            },
          }}

        >
          {blok.blocks.map((blok, index) => (
            <SwiperSlide key={blok._uid}>
              <VideoCard img={blok.image} video={blok.video} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          ref={paginationWrapper}
          className="static bullet-pagination w-full flex items-center justify-center mt-6 lg:mt-2vw space-x-4 lg:hidden"
        ></div>
      </div>

      <div className="section-container two-columns">
        <h2
          className="fs-24px text-wodden-purple font-helvetica font-medium"
          dangerouslySetInnerHTML={{ __html: bottom_title }}
        ></h2>
        <div>
          {blok.bottom_blocks.map((blok, index) => (
            <DynamicSubComponent
              blok={blok}
              key={blok._uid}
              isLast={isLastSubBlock(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
