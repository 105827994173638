import React from "react";
import { sbEditable } from '@storyblok/storyblok-editable'

const Copy = ({ blok, isLast }) => {

    const isGrey = blok.grey
    const isLarge = blok.large


    return(
        <p className={`${isLarge ? `fs-24px` : `fs-16px`} ${isGrey ? `text-gray-1 leading-wide` : `text-wodden-purple`} ${isLast ? `mb-0` : `mb-6 lg:mb-2vw`}`}  {...sbEditable(blok)} dangerouslySetInnerHTML={{__html: blok.copy}}>
        </p>
    )
}

export default Copy