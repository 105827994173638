import React, { useState, useRef } from "react";
import CurrencyInput from 'react-currency-input-field';

const ContactDummy = ({ blok }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = formRef.current;
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => alert(error));

    setFormSubmitted(true);
  };

  return (
    <section>
      <div className="section-container two-columns wide-display" >
        <div></div>

        <div className="flex flex-col justify-center">
          <form
            className="space-y-3 lg:space-y-[19px]"
            onSubmit={handleSubmit}
            ref={formRef}
            data-netlify="true"
            method="post"
            name="contact"
          >
            <input type="hidden" name="form-name" value="contact" />

            <input
              type="text"
              name="name"
              placeholder="Name"
              required
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <input
              type="number"
              name="phone"
              placeholder="Phone Number"
              required
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <input
              type="email"
              name="email"
              placeholder="Email address"
              required
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <input
              type="number"
              name="postcode"
              placeholder="Postcode"
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <input
            decimalsLimit={0}
            prefix="$"
            type={"number"}

              name="household=income"
              placeholder="Household Income "
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
             <input
            decimalsLimit={0}
            prefix="$"
            type={"number"}

              name="superannuation-balance"
              placeholder="Super-Annuation Balance"
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <input
            decimalsLimit={0}
            prefix="$"
                type={"number"}
              name="investable-funds"
              placeholder="Investable Funds/ Equity Balance "
              className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            />
            <textarea
              placeholder="Comment"
              name="comment"
              className="resize-none h-[100px] lg:h-10vw font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
            ></textarea>
            {formSubmitted && (
              <p className="fs-18px py-4 font-bold">{`Thankyou for your submission. We'll be in touch shortly.`}</p>
            )}
            <div className="pt-5 lg:pt-4vw">
              <button
                className={`btn-primary-default inline-block text-center transition-opacity ${formSubmitted ? `pointer-events-none opacity-75` : ``}`}
              >{formSubmitted ? `Submitted` : `Send`}</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactDummy;
