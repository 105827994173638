import React, { useState, Fragment } from "react";
import FluidImage from "./fluidImage";
import { Dialog, Transition } from "@headlessui/react";


function VideoPlay(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79 79"
      {...props}
    >
      <path
        d="M79 39.5C79 61.315 61.315 79 39.5 79S0 61.315 0 39.5 17.685 0 39.5 0 79 17.685 79 39.5z"
        fill="#D9D9D9"
        fillOpacity={0.5}
      />
      <path d="M56 39.5L29.75 54.655v-30.31L56 39.5z" fill="#fff" />
    </svg>
  );
}


function VideoCard({ img, video }) {
  const videoFile = video.filename;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="aspect-[821/445] w-full h-full object-cover relative">
      <FluidImage image={img} />
      <div className="w-full h-full absolute inset-0 items-center justify-center flex px-theme">
        <button
          className="duration-600 transition-transform hover:scale-110"
          onClick={() => setModalOpen(true)}
        >
            <span className="sr-only">Play video</span>
          <VideoPlay className="w-[80px] h-[80px]" />
        </button>
      </div>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-600"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-600"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden  bg-black transition-all max-w-7xl px-theme w-screen aspect-video">
                  <video
                    className="w-full aspect-video h-full"
                    controls
                    src={videoFile}
                    autoPlay
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default VideoCard;
