import React from "react";
import FluidImage from "./fluidImage";

const ResImg = ({ mobile, desktop }) => {
  return (
    <>
      <div className="hidden lg:block pointer-events-none w-full h-full object-cover">
        <FluidImage image={desktop} class='w-full h-full'/>
      </div>

      <div className="lg:hidden pointer-events-none w-full h-full object-cover">
        <FluidImage image={mobile} class='w-full h-full'/>
      </div>
    </>
  );
};

export default ResImg;
