import ResImg from "components/resImg";
import ResVideo from "components/resVideo";
import React from "react";

function Hero({ blok }) {
  const title = blok.title;

  const hasVideo = blok.desktop_video?.filename != "";

  const scrollDown = () => {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
      <section className="bg-wodden-purple relative z-10 h-screen">
        <div className="absolute inset-0 w-full h-full -z-20">
          {blok.desktop_image && hasVideo == false && (
            <ResImg mobile={blok.mobile_image} desktop={blok.desktop_image} />
          )}

          {hasVideo == true && (
            <ResVideo
              mobilePoster={blok.mobile_image}
              desktopPoster={blok.desktop_image}
              desktopVideo={blok.desktop_video?.filename}
              mobileVideo={blok.mobile_video?.filename}
            />
          )}
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black -z-10 opacity-60"></div>

        <div className="section-container relative h-screen flex flex-col justify-end items-center py-8 lg:py-4vw">
          <h1 className="fs-24px text-center w-full px-6 text-white absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            {title}
          </h1>
          {blok.show_chevron == true && (
            <span
              className="text-white fs-16px text-center cursor-pointer"
              onClick={scrollDown}
            >
              Scroll to begin.
            </span>
          )}
        </div>
      </section>
  );
}

export default Hero;
