import React from "react";
import Link from "utils/link";

const VARIANT_DEFAULT = "btn-primary-default inline-block text-center";
const VARIANT_2 = "btn-primary-2 inline-block text-center";

const variants = [VARIANT_DEFAULT, VARIANT_2];

function Button({ label, variant = 0, link }) {

  if (link != null && link != "") {
    return(
      <>
      <Link className={variants[variant]} to={link.cached_url}>
        {label}
      </Link>
      </>
    )
  }
  return (<button className={variants[variant]}>{label}</button>);
}

export default Button;
