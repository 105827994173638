import useMediaQuery from "hooks/useMediaQuery";
import React from "react";
import FluidImage from "./fluidImage";

function TeamCard({ img, title, subtitle, desc }) {
  const isAbove768px = useMediaQuery("(min-width : 768px)");

  return (
    <div className='flex flex-col justify-between'>
      <div className="aspect-[500/650] object-cover w-full mb-8 lg:mb-2vw overflow-hidden">
        <FluidImage image={img} />
      </div>

      <div className={`${isAbove768px ? "" : "section-container"} flex-grow flex justify-start flex-col`}>
        <div className="lg:space-y-0_1vw mb-4 lg:mb-1_4vw">
          <p className="font-helvetica text-wodden-purple fs-24px lh-1">
            {title}
          </p>
          <p className="font-helvetica text-gray-1 fs-24px">{subtitle}</p>
        </div>

        <p className="fs-16px text-wodden-purple mb-5 lg:mb-1_4vw">{desc}</p>

        <p className="fs-16px text-wodden-purple cursor-pointer w-fit hidden">
          Read more
        </p>
      </div>
    </div>
  );
}

export default TeamCard;
