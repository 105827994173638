import React from "react";
import Copy from "components/dualTextBlocks/copy";
import RichTextBlock from "./dualTextBlocks/richTextBlock";
import TitleWithCopy from "./dualTextBlocks/titleWithCopy";

const Components = {
    "copy": Copy,
    "richtext": RichTextBlock,
    "title_with_copy": TitleWithCopy
};

const DynamicSubComponent = ({ blok, isLast }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component blok={blok} isLast={isLast} />;
  }
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};


export default DynamicSubComponent;