import FluidImage from "components/fluidImage";
import React from "react";

function SideBySideImgSection({
  children,
  img,
  reverse = false,
  SideComponent,
}) {

  return (
    <section className='max-w-full'>
    <div
      className={`lg:space-x-[min(77px,5.636896046852123vw)] section-container two-columns image-container ${
        reverse
          ? `lg:flex-row-reverse`
          : `lg:flex-row`
      }`}
    >
     <div
          className={`lg:!max-w-[min(621px,32vw)] flex flex-col justify-center w-full ${
            reverse ? `` : `pt-[30px] lg:pt-0`}`}
        >
          {children}
        </div>

      {SideComponent ? (
        
        <SideComponent />
      ) : (
        <div className={`w-full h-full relative flex -mx-[43px] lg:mx-0 ${reverse ? `lg:justify-end lg:pr-[min(77px,5.636896046852123vw)]` : `order-first lg:order-[unset]`}`}>
        <div
          className="h-[626px] lg:h-[min(695.59px,40.995607613469986vw)] object-cover lg:aspect-video w-full lg:w-[unset]"
        >
          <FluidImage image={img} />
          </div>
        </div>
      )}

    
    </div>
    </section>
  );
}

export default SideBySideImgSection;
