import React from "react";
import Accordion from "components/Accordion";
import { render } from "storyblok-rich-text-react-renderer";

const FaqSection = ({ blok }) => {
  const title = blok.title;

  return (
    <section>
      <div className="section-container">
        {title != "" && title != null && (
          <p className="fs-24px text-wodden-purple mb-10 lg:mb-4vw font-helvetica font-medium">
            {title}
          </p>
        )}
        <div className="border-t-1px">
          {blok.blocks &&
            blok.blocks.map((blok, index) => {
              return (
                <Accordion
                  title={blok.title}
                  key={`faq-${index}`}
                >
                  <div className='rich-text pb-0'>{render(blok.copy)}</div>
                </Accordion>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
