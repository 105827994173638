import SideBySideImgSection from "layouts/SideBySideImgSection";
import React from "react";
import VideoCard from "components/VideoCard";

function CaseStudy({ blok }) {
  const SideComponent = () => {
    return <VideoCard img={blok.image} video={blok.video} />;
  };

  return (
    <div className="relative">
    <section className='section-container lg:absolute inset-0 flex items-center pb-[70px] lg:pb-0'>
        <div>
      <p className="fs-24px text-wodden-purple mb-6 lg:mb-1_8vw">
        {blok.title}
      </p>

      <div className="space-y-1 lg:space-y-0_2vw">
        {blok.purchase_price && (
          <div>
            <span className="fs-24px font-helvetica text-gray-1">
              Purchase price:
            </span>{" "}
            <span className="fs-24px font-helvetica text-wodden-purple">
              {blok.purchase_price}
            </span>
          </div>
        )}
        {blok.rent_achieved && (
          <div>
            <span className="fs-24px font-helvetica text-gray-1">
              Rent achieved per week:
            </span>{" "}
            <span className="fs-24px font-helvetica text-wodden-purple">
              {blok.rent_achieved}
            </span>
          </div>
        )}
        {blok.tax_rebate && (
          <div>
            <span className="fs-24px font-helvetica text-gray-1">
              Tax rebate year one:
            </span>{" "}
            <span className="fs-24px font-helvetica text-wodden-purple">
              {blok.tax_rebate}
            </span>
          </div>
        )}

        {blok.yield_after_tax && (
          <div>
            <span className="fs-24px font-helvetica text-gray-1">
              Rental yield after tax:
            </span>{" "}
            <span className="fs-24px font-helvetica text-wodden-purple">
              {blok.yield_after_tax}
            </span>
          </div>
        )}
      </div></div>
    </section>

    <div className="grid lg:grid-cols-2">
            <div></div>
            <SideComponent/>

    </div>
    </div>
  );
}

export default CaseStudy;
