import React from "react";

const ResVideo = (props) => {
  return (
    <>
      <video
        className="w-full h-full hidden lg:block object-cover"
        src={props.desktopVideo}
        autoPlay
        muted
        loop
        playsinline
        disablepictureinpicture
        disableremoteplayback
        x-webkit-airplay="deny"
        poster={props.desktopPoster?.filename}
      ></video>

      <video
        className="w-full h-full lg:hidden object-cover"
        src={props.mobileVideo}
        muted        
        loop
        autoPlay
        playsinline
        disablepictureinpicture
        disableremoteplayback
        x-webkit-airplay="deny"
        poster={props.mobilePoster?.filename}
      ></video>
    </>
  );
};

export default ResVideo;
