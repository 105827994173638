import React from "react";
import WoodenSection from "layouts/WoodenSection";

const TestimonialSmallHeight = ({ blok }) => {

  const hasCta = blok.name != "" && blok.name != null
  const wider = blok.wide_display
  
  return (
    <WoodenSection>
      <p
        className={`fs-24px text-white text-center font-medium ${hasCta ? `mb-8 lg:mb-2vw` : ``} max-w-[40rem] ${wider ? `lg:max-w-[min(1228px,64vw)]` : `lg:max-w-[min(670px,50vw)]`} font-helvetica`}
        dangerouslySetInnerHTML={{ __html: blok.copy }}
      ></p>
      {hasCta && (
        <p className="text-center text-gray-1 fs-24px">{blok.name}</p>
      )}
    </WoodenSection>
  );
};

export default TestimonialSmallHeight;
