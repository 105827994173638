import React from "react";
import TeamCard from "components/TeamCard";

const TeamMembers = ({ blok }) => {
  const title = blok.title;

  return (
    <section>
      {title != null && title != "" && (
        <div className="section-container mb-8 lg:mb-3vw">
          <h2 className="fs-24px">{title}</h2>
        </div>
      )}

      <div
        className={`lg-section-container grid md:grid-cols-3 gap-12 lg:gap-4vw`}
      >
        {blok.blocks &&
          blok.blocks.map((blok, index) => {
            return (
              <TeamCard
                key={`team-${index}`}
                img={blok.image}
                title={blok.name}
                subtitle={blok.title}
                desc={blok.copy}
              />
            );
          })}
      </div>
    </section>
  );
};

export default TeamMembers;
