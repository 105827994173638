import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Link = ({
	children,
	to,
	activeClassName,
	partiallyActive,
	swipe,
	...other
}) => {

	if (to == null || to == "") {
		to = "/"
	}

	if (to == 'index' || to == 'index.html' || to == "/index" || to == "/index.html") {
		to = '/'
	}

	const checkExternal = /^(http|https):/.test(to)

	// check mailto tel etc
	const checkUrlScheme = /^(mailto|tel):/.test(to)

	if (!checkExternal && !checkUrlScheme) {

		const hash = to.split('#')[1]

		if (hash) {
			return (
				<AnchorLink
					to={to}
					activeClassName={activeClassName}
					partiallyActive={partiallyActive}
					{...other}
				>
					{children}
				</AnchorLink>


			)
		}

		// Check if first character of the link is a slash
		if (to.charAt(0) !== '/') {
			to = `/${to}`
		}

		if (swipe){
			return (
				<GatsbyLink
					to={to}
					swipe top="entry"
					activeClassName={activeClassName}
					partiallyActive={partiallyActive}
					{...other}
				>
					{children}
				</GatsbyLink>
			)
		}

		return (
			<AniLink
				to={to}
				fade  
				color="#000"
				duration={0.6}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				{...other}
			>
				{children}
			</AniLink>
		)
	}

	return (
		<a href={to} {...other} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	)
}

export default Link
