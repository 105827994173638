import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

const TitleWithCopy = ({ blok, isLast }) => {
  return (
    <div className={`space-y-4 lg:space-y-1vw ${!isLast ? `pb-8 lg:pb-3vw` : ``}`}>
      <p className="fs-24px text-wodden-purple">{blok.title}</p>
      <div className="rich-text">{render(blok.copy)}</div>
    </div>
  );
};

export default TitleWithCopy;
