import React from "react";

import Button from "components/Button";
import WoodenSection from "layouts/WoodenSection";

function CtaBlock({blok}) {
  return (
    <WoodenSection>
      <p className="fs-24px text-center max-w-[18rem] lg:max-w-[min(559px,28vw)] text-white mb-6 lg:mb-3vw font-medium" dangerouslySetInnerHTML={{__html: blok.copy}}>
      </p>
      <Button label={blok.cta_title} link={blok.cta_link} variant={1} />
    </WoodenSection>
  );
}

export default CtaBlock;