/* eslint-disable */

import CapChart from "components/CapChart";
import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import WoodenSection from "layouts/WoodenSection";
import CountUp from 'react-countup';

const calculateRetirementIncome = (age, retireAge, income, currentSavings) => {
  var t = 0.095 * income - 0.095 * income * 0.15,
    a = currentSavings + t + 0.043 * currentSavings,
    r = [];

  a -= 0.0128 * a;

  for (var r = [Math.floor(a)], s = 1; s < retireAge - age; s++) {
    var n = r[s - 1] + 0.043 * r[s - 1] + t;
    n -= 0.0128 * n;
    r.push(Math.floor(n));
  }

  return r;
};

  // const generate labels 

  function generateArray(x, y) {
    let arr = [];
    for (let i = x; i <= y; i++) {
      arr.push(i);
    }
    return arr;
  }
  

const RetirementCalculator = () => {
  const [age, setAge] = useState(30);
  const [retirementAge, setRetirementAge] = useState(67);
  const [retirementIncome, setRetirementIncome] = useState([]);
  const [currentIncome, setCurrentIncome] = useState(100000);
  const [currentSavings, setCurrentSavings] = useState(100000);
  const [labels, setLabels] = useState(generateArray(age, retirementAge - 1));

  const [totalSavings, setTotalSavings] = useState(retirementIncome.slice(-1)[0]);

  const [dataState, setDataState] = useState({
    labels,
    datasets: [
      {
        data: calculateRetirementIncome(
          age,
          retirementAge,
          currentIncome,
          currentSavings
        )
      }
    ],
  }
  );

  const onSubmit = (e) => {
 
    e.preventDefault();

    // get form data 

    const formData = new FormData(e.target);

    // set state


    const ageForm = parseInt(formData.get("age"));
    const currentIncomeForm = parseInt(formData.get("currentIncome").replace(/[$,]/g, ''));
    const currentSavingsForm = parseInt(formData.get("currentSavings").replace(/[$,]/g, ''));

    setAge(ageForm);
    setCurrentIncome(currentIncomeForm);
    setCurrentSavings(currentSavingsForm);


    // set labels

    const labels = generateArray(ageForm, retirementAge - 1);

    setLabels(labels);

    // set data

    const retirementData = calculateRetirementIncome(
      ageForm,
      retirementAge,
      currentIncomeForm,
      currentSavingsForm
    )





    // set total savings

    setTotalSavings(retirementData.slice(-1)[0]);

    
    setDataState({
      labels,
      datasets: [
        {
          data: retirementData
        }
      ],
    }); 

    window.console.log(dataState);

  };

  
  const isSSR = typeof window === "undefined"

  

  return (
    <>
      <section className='mb-[min(120px,13vh)] lg:mb-7vw'>
        <div className="section-container two-columns">
          <div>
            <p className="fs-24px text-wodden-purple font-helvetica mb-6 lg:mb-1_8vw font-bold">
              Retirement Calculator{" "}
            </p>
            <form className="space-y-3" onSubmit={onSubmit}>
              <input
                type="number"
                placeholder="Age"
                name="age"
                min={15}
                required
                max={retirementAge}
                className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
              />
              <CurrencyInput
                decimalsLimit={0}
                prefix="$"
                name="currentIncome"
                placeholder="Yearly Income"
                className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
              />

              <CurrencyInput
                decimalsLimit={0}
                prefix="$"
                name="currentSavings"
                placeholder="Current Super Balance"
                className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
              />
              <div className="inline-block pt-4">
                <button
                  className="btn-primary-default"
                  type="submit"
                >
                  Calculate
                </button>
              </div>
            </form>
          </div>
          <div className="aspect-square">
            {
              labels!= null && labels.length > 0 && dataState.datasets[0].data.length > 0 && !isSSR ? (
                <CapChart data={dataState} key={totalSavings} totalSavings={totalSavings}/>
              ) : null
            }
          </div>
        </div>
      </section>
      <WoodenSection>
      <p className="text-center text-gray-1 fs-24px mb-4">          Total amount at retirement age</p>
      <p
        className={`text-5xl font-bold text-white text-center max-w-[40rem] lg:max-w-[min(670px,50vw)] font-helvetica`}
      ><CountUp
      end={totalSavings}
      duration={2}
      separator=","
      decimals={0}
      decimal=","
      prefix="$"
      key={totalSavings}
    />
      
    </p>


      </WoodenSection>
    </>
  );
};

export default RetirementCalculator;
