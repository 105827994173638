import React from "react";

import Button from "components/Button";
import SideBySideImgSection from "layouts/SideBySideImgSection";
import DynamicSubComponent from "components/dynamicSubComponent";

function TextWithImage({ blok }) {
  const title = blok.title;

  const isLastBlock = (index) => {
    return index == blok.blocks.length - 1;
  };
  const reverse = blok.reverse_on_desktop;

  return (
    <SideBySideImgSection img={blok.image} reverse={reverse}>
      {title != null && title != "" && (
        <h2 className="fs-24px text-wodden-purple mb-6 lg:mb-2vw font-helvetica font-medium">
          {title}
        </h2>
      )}

      {blok.blocks.map((blok, index) => {
        return (
          <DynamicSubComponent
            blok={blok}
            key={blok._uid}
            isLast={isLastBlock(index)}
          />
        );
      })}
        {
            blok.cta_title != null && blok.cta_title != "" && (
              <div className={`pt-[63px] lg:pt-[45px] ${reverse ? `pb-[30px] lg:pb-0` :  ``}`} >
              <Button label={blok.cta_title} link={blok.cta_link} />
                </div>
            )
        }
    </SideBySideImgSection>
  );
}

export default TextWithImage;
